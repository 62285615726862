<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">工艺管理</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
        v-auth="auth_equipment2.TECHNOLOGY_ADD_UPDATE"
      >新增
      </a-button
      >
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="所属部门">
              <a-tree-select
                v-model="searchParams.departmentId"
                allowClear
                :treeData="departmentList"
                placeholder="请选择部门"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="工艺名称">
              <a-input v-model="searchParams.name" placeholder="请输入工艺名称" allowClear/>
            </a-form-item>
            <a-form-item label="工艺代码">
              <a-input v-model="searchParams.code" placeholder="请输入工艺代码" allowClear/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        @change="tableChange"
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :customRow="handleView"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="systemModule" slot-scope="systemModule">
          {{ systemModule ==='facility_info'?'设施':'设备' }}
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)" v-auth="auth_equipment2.TECHNOLOGY_ADD_UPDATE" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" v-auth="auth_equipment2.TECHNOLOGY_DELETE" @click.stop>删除</a>
        </span>
      </a-table>
    </a-card>
    <a-modal v-model="addModal" title="配置">
      <Form ref="addRef" type="add" @addSuccess="addSuccess" :departmentList="departmentList" @addError="addError"></Form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal" title="工艺编辑">
      <Form
        ref="editRef"
        type="edit"
        :departmentList="departmentList"
        @editSuccess="editSuccess"
        @editError="editError"
      ></Form>
    </a-modal>
    <a-drawer
      title="工艺详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="450px"
      :visible="detailDrawer"
      :mask="false"
    >
      <Detail ref="viewRef"></Detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import Form from './Form'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import { configColums } from './common/common'
  import Detail from './Detail'

  export default {
    components: { PageLayout, Form, Detail },
    mixins: [entityCRUDWithCopy],
    name: 'List',
    data () {
      return {
        categoryId: null,
        sup_this: this,
        entityBaseUrl: SERVICE_URLS.equipment2.technologyInfo,
        tableData: [],
        initColumns: configColums(),
        searchParams: {
          departmentId: undefined,
          name: '',
          code: ''
        },
        departmentList: []
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.departmentList = data.body
          }
        })
      },
      handleView(record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            },
          },
        }
      },
      addSuccess (addType) {
        if (addType === 'copy') {
          // 表示不关闭Modal
          this.addLoading = false
        } else if (addType === 'continue') {
          this.addLoading = false
          this.emptyForm()
        } else {
          // 直接关闭Modal
          this.emptyForm()
          this.addLoading = false
          this.addModal = false
        }
        this.addLoading = false
        this.search()
      },
      emptyForm() {
        this.$refs.addRef.selectData = null
      }
    }
  }
</script>

<style scoped></style>
